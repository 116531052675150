import Cookies from 'js-cookie'

let PrivacyPolicyMessage = {
    init: function () {
        this.message()
    },
    message: function () {
        let messageContainer = document.getElementById('privacy-policy-message')
        if (messageContainer !== null) {
            let closeBtn = messageContainer.querySelector('.privacy-policy-message-button')
            if (closeBtn !== null) {
                closeBtn.addEventListener('click', function (e) {
                    e.preventDefault();
                    Cookies.set('privacy-policy-message-accepted', '1', { expires: 365, path: '/', secure: true });
                    messageContainer.remove();
                });
            }
        }
    }
}

PrivacyPolicyMessage.init()
